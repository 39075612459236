:root {
  /* Variáveis para temas padrão */
  --primary-color: #894F79;
  --primary-hover-color: #722660;
  --secondary-color: #138496;
  --danger-color: #dc3545;
  --background-color: #f7f8fc;
  --card-background-color: #ffffff;
  --text-color: #333;
  --text-secondary-color: #666;
  --border-color: #ccc;
  --table-header-background: #f1f1f1;
  --table-row-background: #f9f9f9;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Tema Light */
[data-theme="light"] {
  --primary-color: #54412F;
  --primary-hover-color: #3e2f22;
  --secondary-color: #138496;
  --danger-color: #dc3545;
  --background-color: #ffffff;
  --card-background-color: #ffffff;
  --text-color: #333;
  --text-secondary-color: #666;
  --border-color: #ccc;
  --table-header-background: #f1f1f1;
  --table-row-background: #f9f9f9;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Tema Dark */
[data-theme="dark"] {
  --primary-color: #4a4e69;
  --primary-hover-color: #22223b;
  --secondary-color: #9a8c98;
  --danger-color: #e63946;
  --background-color: #1d1e2c;
  --card-background-color: #2a2b4d;
  --text-color: #f5f5f5;
  --text-secondary-color: #c9c9c9;
  --border-color: #3b3c5e;
  --table-header-background: #3f4157;
  --table-row-background: #2e2f4a;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

/* Tema Daltonic */
[data-theme="daltonic"] {
  --primary-color: #d1911e;
  --primary-hover-color: #aa7329;
  --secondary-color: #2271b1;
  --danger-color: #ea3e3e;
  --background-color: #f7f8fc;
  --card-background-color: #ffffff;
  --text-color: #333;
  --text-secondary-color: #666;
  --border-color: #ccc;
  --table-header-background: #f1f1f1;
  --table-row-background: #f9f9f9;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Tema Verde */
[data-theme="green-theme"] {
  --primary-color: #3c6e47;
  --primary-hover-color: #2b5332;
  --secondary-color: #68a357;
  --danger-color: #e63946;
  --background-color: #e6f0e9;
  --card-background-color: #ffffff;
  --text-color: #2d3a2c;
  --text-secondary-color: #6c7b66;
  --border-color: #cce0d1;
  --table-header-background: #d7e3db;
  --table-row-background: #edf4ef;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Tema Marrom */
[data-theme="brown-theme"] {
  --primary-color: #7a5230;
  --primary-hover-color: #593e24;
  --secondary-color: #b07d62;
  --danger-color: #dc3545;
  --background-color: #f6ece3;
  --card-background-color: #ffffff;
  --text-color: #5c4435;
  --text-secondary-color: #8c7a6b;
  --border-color: #d6c1b1;
  --table-header-background: #e4d3c3;
  --table-row-background: #f4ece6;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Tema Cinza e Azul */
[data-theme="blue-grey-theme"] {
  --primary-color: #4a6a8b;
  --primary-hover-color: #324c61;
  --secondary-color: #9aa6b1;
  --danger-color: #e63946;
  --background-color: #f0f4f7;
  --card-background-color: #ffffff;
  --text-color: #2e3a45;
  --text-secondary-color: #687785;
  --border-color: #ccd6df;
  --table-header-background: #e2e8ed;
  --table-row-background: #f5f9fc;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.users-container {
  padding: 20px;
  background-color: var(--background-color);
}

.users-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.btn-new-user,
.btn-secondary {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-new-user:hover,
.btn-secondary:hover {
  background-color: var(--primary-hover-color);
}

.users-filters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.users-filters input,
.users-filters select {
  border-radius: 5px;
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  background-color: var(--card-background-color);
  color: var(--text-color);
}

.users-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.info-card {
  background-color: var(--card-background-color);
  border-radius: 8px;
  padding: 10px;
  box-shadow: var(--box-shadow);
  transition: transform 0.2s ease-in-out;
  flex: 1 1 calc(25% - 20px); /* 4 cartões por linha */
  min-width: 200px;
}

.info-card:hover {
  transform: translateY(-5px);
}

.info-card .card-body {
  padding: 0;
}

.info-card .title {
  font-size: 14px;
  color: var(--text-secondary-color);
  margin-bottom: 5px;
}

.info-card .text-value {
  font-size: 22px;
  font-weight: bold;
  color: var(--text-color);
}

.section-divider {
  width: 100%;
  height: 1px;
  background-color: var(--border-color);
  margin: 20px 0;
}

.chart-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.bar-chart,
.doughnut-chart {
  flex: 1;
  height: 300px; /* Ajuste de acordo com a altura desejada */
  background-color: var(--card-background-color);
  box-shadow: var(--box-shadow);
  border-radius: 8px;
}

.table {
  width: 100%;
  margin-bottom: 20px;
  background-color: var(--card-background-color);
  border-collapse: collapse;
  box-shadow: var(--box-shadow);
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.table th {
  background-color: var(--table-header-background);
  font-weight: 600;
  color: var(--text-color);
}

.table tbody tr:nth-child(even) {
  background-color: var(--table-row-background);
}

.ctn-button button {
  margin-right: 5px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.ctn-button button:hover {
  background-color: white;
  color: var(--primary-color);
}

.ctn-button .btn-trash {
  background-color: var(--danger-color);
}

.ctn-button .btn-trash:hover {
  background-color: white;
  color: var(--danger-color);
}

.load-more {
  text-align: center;
  margin-top: 20px;
}

.load-more button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.load-more button:hover {
  background-color: var(--primary-hover-color);
}

.create-user-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--card-background-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
}

.create-user-container h2 {
  margin-bottom: 20px;
  text-align: center;
  color: var(--text-color);
}

.create-user-container .form-group {
  margin-bottom: 15px;
}

.create-user-container .form-control {
  border-radius: 5px;
  border: 1px solid var(--border-color);
  padding: 10px;
  background-color: var(--card-background-color);
  color: var(--text-color);
}

.create-user-container .btn {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.create-user-container .btn:hover {
  background-color: var(--primary-hover-color);
}

.create-user-container .btn + .btn {
  margin-top: 10px;
}

.dashboard-analytics {
  margin-top: 20px;
}

.chart-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.bar-chart,
.doughnut-chart {
  flex: 1;
  padding: 20px;
  height: 300px;
  background-color: var(--card-background-color);
  box-shadow: var(--box-shadow);
  border-radius: 8px;
}

.filters-buttons-container {
  margin-bottom: 20px;
}

.buttons-container .create-button,
.buttons-container .mr-2 {
  margin-right: 10px;
}

.filters-container .mr-2,
.filters-container select,
.filters-container input {
  margin-right: 10px;
}

.filters-container label {
  margin-right: 5px;
}

.filters-buttons-container .d-flex {
  flex-wrap: wrap;
}

.filters-container {
  display: flex;
  align-items: center;
}

.header {
  background-color: var(--primary-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.header-logo {
  height: 40px;
  margin-right: 20px;
}

.ctn-token-display {
  display: flex;
  gap: 6px;
}

.ctn-token-display .bi-lock {
  font-weight: bold;
}

.ctn-token-display .bi-eye {
  color: red;
  border: 1px solid red;
  height: 25px;
  display: flex;
  align-items: center;
  width: 25px;
  justify-content: center;
}

.ctn-token-display .bi-eye:hover {
  background-color: red;
  color: white;
}

.step-title {
  color: #475569;
  font-weight: bold;
}

.user-dropdown-toggle {
  background-color: transparent;
  border: none;
  color: white; /* Mantém o texto do e-mail sempre branco */
}

.user-dropdown-toggle:hover {
  color: var(--hover-text-color);
}

.right-side {
  margin-left: auto;
}

.dropdown-menu {
  background-color: #ffffff; /* Fundo branco para o dropdown */
  border: 1px solid var(--border-color); /* Adiciona uma borda para contraste */
}

.dropdown-item {
  color: var(--primary-color); /* Mantém a cor do texto dos itens */
}

.dropdown-item:hover {
  background-color: var(--primary-hover-color);
  color: white; /* Cor branca para o texto ao passar o mouse */
}


.chart-wrapper {
  flex: 1;
  min-width: 300px; /* Garante que os gráficos tenham um tamanho mínimo adequado */
}

.chart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.bar-chart, .doughnut-chart {
  height: 300px; /* Ajuste conforme necessário */
}
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-controls {
  display: flex;
  gap: 10px;
}

.btn-icon {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-icon:hover {
  background-color: var(--primary-hover-color);
}

.btn-icon i {
  font-size: 20px;
}

/* Footer Styles */
.footer {
  background-color: var(--primary-color);
  color: white;
  padding: 5px 10px;  /* Altura reduzida */
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content {
  display: flex;
  align-items: center;  /* Alinha a imagem e o texto lado a lado */
}

.footer-logo {
  width: 80px; /* Tamanho da logo reduzido para caber bem com o texto */
  margin-right: 60px; /* Espaço entre a logo e o texto */
}

.footer-text {
  color: white;
  font-size: 12px; /* Tamanho da fonte ajustado */
  margin: 0;
}

.footer a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: var(--secondary-color);
}








