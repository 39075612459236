/* frontend/src/styles/Form.css */
:root {
  --primary-green: #3E9E4E;
  --primary-yellow: #FFD700;
  --primary-orange: #FFA500;
  --light-gray: #F8F8F8;
  --dark-gray: #A9A9A9;
  --white: #FFFFFF;
  --button-brown: #54412F;
}

body {
  background-color: var(--light-gray);
  color: var(--dark-gray);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

header, footer {
  background-color: var(--primary-green);
  color: var(--white);
  padding: 1rem;
}

button {
  background-color: var(--button-brown);
  color: var(--white);
}

a {
  color: var(--primary-yellow);
}

.btn-brown {
  background-color: var(--button-brown);
  color: var(--white);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.btn-brown:hover {
  background-color: #3e2e25;
}

.login-page {
  background-image: url('/public/Background-login.jpeg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem; /* Adiciona espaçamento abaixo do contêiner de login */
}

.login-box {
  background-color: rgba(255, 255, 255, 0.8); /* Fundo semi-transparente */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 400px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
}

.separator .line {
  flex-grow: 1;
  border: 0;
  height: 1px;
  background: var(--dark-gray);
}

.separator span {
  margin: 0 1rem;
  color: var(--dark-gray);
}

.logo {
  display: block;
  margin: 0 auto 1rem;
  width: 200px;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--dark-gray);
  font-size: 16px;
}

.btn-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
